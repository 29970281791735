<template>
  <v-card-text>
    <v-card>
      <v-card-text>
        <screeners-table
          :screeners="screeners"
          :screenersCount="screenersCount"
          :isLoading="isLoading"
          @updateScreeners="handleScreenersUpdate"
        >
        </screeners-table>
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import ScreenersTable from "@admin/components/screeners/Table";

export default {
  name: "ScreenersIndex",
  components: {
    ScreenersTable,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState("screeners", ["screeners", "screenersCount"]),
    ...mapGetters(["acceptRoles"]),
    showScreenerAddButton() {
      return false; // this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
  },
  beforeDestroy() {
    this.clearScreeners();
  },
  methods: {
    ...mapActions("screeners", [
      "getScreeners",
      "clearScreeners",
    ]),
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
    async handleScreenersUpdate(options) {
      this.isLoading = true;
      await this.getScreeners(options);
      this.isLoading = false;
    },
  },
  async mounted() {
    if (this.screeners === undefined || this.screeners.length === 0) {
      this.isLoading = true;
      await this.getScreeners();
      this.isLoading = false;
    }
    this.setBreadcrumbs();
    this.setTitleObject("Screeners");
    let actionButtons = [];
    if (this.showScreenerAddButton) {
      actionButtons = [
        {
          attributes: {
            to: "/screener/add",
          },
          component: "AddButton",
          text: "Add Screener",
          index: 0,
        },
      ];
    }
    this.setActionButtons(actionButtons);
  },
  destroyed() {
    this.clearScreeners();
    this.clearActionButtons();
    this.clearTitleObject();
  },
};
</script>

<style scoped></style>
